"use client";

import { useEffect, useState } from "react";
import CommnetsArea from "./CommnetsArea";

function Comments({ postId, commentStatus }) {
    const [data, setData] = useState([]);

    async function getComments(postId) {
        if (!postId) return [];

        const URL = `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/comments?post=${postId}&per_page=10`;
        const res = await fetch(URL);
        const comments = await res.json();
        setData(comments?.data || []);
    }

    useEffect(() => {
        getComments(postId);
    }, [postId]);

    return <CommnetsArea data={data} post_id={postId} commentStatus={commentStatus} />;
}

export default Comments;
